import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import leftbg from "../../assets/images/leftbg.png";
import "./login.css";
import { loginOTP } from "../../services/auth";
import { toastCatchError } from "../../common/common";
import Logo from "../../assets/images/Logo.png";

const Login = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneEmpty, setPhoneEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault()
    if (phoneNumber.length > 8) {
      setPhoneEmpty(false)

      try {
        setLoading(true)
        let result = await loginOTP({
          phone_number: phoneNumber
        })
        if(result.status == 200){
          navigate("/verify", { state: { phoneNumber: phoneNumber, from: "login" } })
        }
        
      } catch (error) {
        toastCatchError(error)
      } finally {
        setLoading(false)
      }

    } else {
      setPhoneEmpty(true)
    }
  };

  return (
    <>
      <section className="LoginPage d-flex align-items-center">
        <div className="custom-container">
          <div className="row align-items-center justify-content-center GradientColor">
            {/* Left Side Bar Sec */}
            <div className="col-md-6 nopadding PositionRelative">
              <div className="LeftSideDiv">
                <h2 className="h2-style">
                  Welcome
                  <br /> Back!
                </h2>
                <p className="Credentinals"> To keep connected with us please login using your credentials. </p>
              </div>
              <div className="leftbg">
                <img src={leftbg} alt="LeftBg" />
              </div>
            </div>
            {/* Left Side Bar Sec Ends */}
            {/* Right Side Bar Sec */}
            <div className="col-md-6 nopadding">
              <div className="RightSideDiv">
                <div className="row">
                  <div className="col-lg-8 col-md-12 offset-lg-2 offset-md-0">
                      
                    <div className="App_Name"><img src={Logo} alt="" width={50} />bitcoinify</div>
                    <div className="PageTitle mt-4">
                      <h2 className="FormHeading">Log in</h2>
                    </div>
                    <form onSubmit={handleLogin}>
                      <div className="row">
                        <div className="col-md-12 mt-4 mb-4">
                          <label className="form-label"> Phone number* </label>
                          <PhoneInput
                            className="PhoneField"
                            defaultCountry="in"
                            value={phoneNumber}
                            onChange={(phoneNumber) => { setPhoneNumber(phoneNumber); }}
                          />

                          {phoneEmpty ? (
                            <p className="text-danger">Phone number is required.</p>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">

                          <button type="submit" disabled={loading ? true : false} onClick={handleLogin} className="ButtonStyle">
                            {
                              loading ? "Loading ..." : "Login"
                            }
                          </button>
                          <div className="SignUphere"> Don't have an account?{" "} <Link to="/signup">Sign Up here</Link>  </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
