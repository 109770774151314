import React, { useState } from "react";
import { toastAlert, toastCatchError } from "../../common/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../../services/auth";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("This field is required."),
      newpassword: Yup.string()
        .required("This field is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Strong password requirements: 8+ characters, uppercase, lowercase, numbers & symbols"
        ),
      confirmpassword: Yup.string()
        .required("This field is required.")
        .oneOf([Yup.ref("newpassword")], "Passwords must match."),
    }),
    onSubmit: async (values) => {
      await updatechangePassword(values);
    },
  });

  const updatechangePassword = async (data) => {
    try {
      setLoading(true);
      const result = await changePassword({
        oldPassword: data.oldPassword,
        newpassword: data.newpassword,
      });
      if (result.status === 200) {
        const userInfo = JSON.parse(localStorage.getItem("authUser"));
        const { token } = userInfo;
        localStorage.setItem(
          "authUser",
          JSON.stringify({ ...result.data, token })
        );
        toastAlert("success", "Profile Updated!");
      }
    } catch (error) {
      toastCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <section className="EditProfileSec d-flex align-items-center">
        <div className="custom-container">
          <div className="row align-items-center justify-content-center GradientColor">
            <div className="col-md-12 nopadding">
              <div className="RightSideDiv">
                <div className="row">
                  <div className="col-lg-6 col-md-12 offset-lg-3 offset-md-0">
                    <div className="PageTitle mt-4 d-flex align-items-center">
                      <h2 className="FormHeading me-3">Change Password</h2>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 mt-4">
                          <label className="form-label">Current Password</label>
                          <input
                            name="oldPassword"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.oldPassword}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.oldPassword && formik.errors.oldPassword ? (
                            <p className="text-danger">{formik.errors.oldPassword}</p>
                          ) : null}
                        </div>

                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className="form-label">New Password</label>
                          <input
                            name="newpassword"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.newpassword}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.newpassword && formik.errors.newpassword ? (
                            <p className="text-danger">{formik.errors.newpassword}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className="form-label">Confirm Password</label>
                          <input
                            name="confirmpassword"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.confirmpassword}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                            <p className="text-danger">{formik.errors.confirmpassword}</p>
                          ) : null}
                        </div>

                        <div className="col-md-12 mt-4">
                          <button
                            type="submit"
                            disabled={loading || formik.values.confirmpassword !== formik.values.newPassword}
                            className="ButtonStyle"
                          >
                            {loading ? "Loading ..." : "Update"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChangePassword;
