import React from 'react'
import "./NotFound.css"
import not404 from "../../assets/images/404.gif"
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div id="notfound">
      <div className="notfound">
        <img src={not404} style={{ width: '450px' }} />
        <div className="notfound-404">
          <h1>Oops!</h1>
          <h2>404 - The Page can't be found</h2>
        </div>
        <Link to="/"> Go TO Homepage </Link>
      </div>
    </div>
  )
}


export default NotFound
