import { useFormik } from 'formik';
import React, { useState } from 'react';
import "react-international-phone/style.css";
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import leftbg from "../assets/images/leftbg.png";
import { toastCatchError } from '../common/common';
import { updateProfile } from '../services/auth';

const PersonalDetail = () => {

  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("authUser"));
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: userInfo?.first_name,
      lastName: userInfo?.last_name,
      dob: userInfo?.date_of_birth,
      addressOne: userInfo?.address_line_one,
      addressTwo: userInfo?.address_line_two,
      postalCode: userInfo?.postal_code,
      state: userInfo?.state,
      country: userInfo?.country,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required."),
      lastName: Yup.string().required("This field is required."),
      dob: Yup.string().required("This field is required."),
      addressOne: Yup.string().required("This field is required."),
      postalCode: Yup.string().required("This field is required."),
      state: Yup.string().required("This field is required."),
      country: Yup.string().required("This field is required."),
      addressTwo: Yup.string().required("This field is required.")
    }),
    onSubmit: (values) => {
      updateProfileDetails(values);
    },
  });

  const updateProfileDetails = async (data) => {
    try {
      setLoading(true)
      let result = await updateProfile({
        first_name: data?.firstName,
        last_name: data?.lastName,
        date_of_birth: data?.dob,
        address_line_one: data?.addressOne,
        address_line_two: data?.addressTwo,
        postal_code: data?.postalCode,
        state: data?.state,
        country: data?.country,
      })
      if (result.status == 200) {

        let userInfo = localStorage.getItem("authUser");
        const { token } = JSON.parse(userInfo)
        localStorage.setItem("authUser", JSON.stringify({ ...result.data, token }));
        setTimeout(() => {
          navigate("/kyc-verification")
        }, 300);
      }

    } catch (error) {
      toastCatchError(error)
    } finally {
      setLoading(false)
    }
  }


  return (
    <>
      <section className='LoginPage d-flex align-items-center'>
        <div className='custom-container'>
          <div className='row align-items-center justify-content-center GradientColor'>
            {/* Left Side Bar Sec */}
            <div className='col-md-6 nopadding PositionRelative'>
              <div className='LeftSideDiv'>
                <h2 className='h2-style'> About You </h2>
                <p className='Credentinals'> Add information that is personal to you, which could identify you for KYC Verification. </p>
              </div>
              <div className='leftbg'>
                <img src={leftbg} alt="LeftBg" />
              </div>
            </div>
            {/* Left Side Bar Sec Ends */}
            {/* Right Side Bar Sec */}
            <div className='col-md-6 nopadding'>
              <div className='RightSideDiv'>
                <div className='row'>
                  <div className='col-lg-8 col-md-12 offset-lg-2 offset-md-0'>
                    <div className='App_Name'>
                      App Name
                    </div>
                    <div className='PageTitle mt-4'>
                      <h2 className='FormHeading'>Personal Details</h2>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className='form-label'>First Name*</label>
                          <input
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.firstName && formik.errors.firstName ? (
                            <p className="text-danger">{formik.errors.firstName}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className='form-label'>Last Name*</label>
                          <input
                            name="lastName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <p className="text-danger">{formik.errors.lastName}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className='form-label'>DOdddB*</label>

                          <input
                            name="dob"
                            type="date"
                            min="1900-01-01"
                            max={new Date().getFullYear() + "-" + (((new Date().getMonth() + 1) < 10) ? "0" : "") + (new Date().getMonth() + 1) + "-" + ((new Date().getDate() < 10) ? "0" : "") + new Date().getDate()}
                            onChange={formik.handleChange}
                            value={formik.values.dob}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.dob && formik.errors.dob ? (
                            <p className="text-danger">{formik.errors.dob}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className='form-label'>Address line 1*</label>
                          <input
                            name="addressOne"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.addressOne}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.addressOne && formik.errors.addressOne ? (
                            <p className="text-danger">{formik.errors.addressOne}</p>
                          ) : null}

                        </div>
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className='form-label'>Address line 2*</label>
                          <input
                            name="addressTwo"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.addressTwo}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.addressTwo && formik.errors.addressTwo ? (
                            <p className="text-danger">{formik.errors.addressTwo}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className='form-label'>Postal Code*</label>
                          <input
                            name="postalCode"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.postalCode}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.postalCode && formik.errors.postalCode ? (
                            <p className="text-danger">{formik.errors.postalCode}</p>
                          ) : null}

                        </div>
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className='form-label'>State*</label>
                          <input
                            name="state"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.state}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <p className="text-danger">{formik.errors.state}</p>
                          ) : null}

                        </div>
                        <div className="col-lg-6 col-md-12 mt-4">
                          <label className='form-label'>Country*</label>
                          <input
                            name="country"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.country}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.country && formik.errors.country ? (
                            <p className="text-danger">{formik.errors.country}</p>
                          ) : null}

                        </div>

                        <div className='col-md-12 mt-4'>
                          <button type="submit" disabled={loading ? true : false} className='ButtonStyle'>
                            {
                              loading ? "Loading ..." : "Save"
                            }
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Right Side Bar Sec Ends */}
          </div>
        </div>
      </section>
    </>
  )
}

export default PersonalDetail