import { useState, CSSProperties } from "react";
import BounceLoader from "react-spinners/BounceLoader";

const override = {
    display: "block",
    position: "absolute",
    width: "150px",
    height: "150px",
    top: "45%",
    left: "45%",
    zIndex: "111"
};

function Loader() {
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");

    return (
        <>
            <div className="sweet-loading" style={{ position: "absolute", height: "100%", width: "100%", background: "#000000c4", zIndex: "11" }}>
            </div>
            
            <BounceLoader
                color="#36d7b7"
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />

            
        </>
    );
}

export default Loader;