import React, { useState } from 'react'

// Mobile honeInput
import { Link } from 'react-router-dom';
import leftbg from "../assets/images/leftbg.png"
import Badge from 'react-bootstrap/Badge';
import { toastAlert, toastCatchError } from '../common/common';
import { kycUpload } from '../services/users';

const KYCverification = () => {

  const [documentType, setDocumentType] = useState("");
  const [document, setDocument] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleUploadKYC = async (e) => {
    e.preventDefault()
    try {
      if (!documentType || !document) {
        setError(true);
      } else {
        setError(false)
        setLoading(true)

        let result = await kycUpload({
          "type": documentType,
          "document": document,
        });
        if(result.status == 200){
          toastAlert("success", "Document Uploaded!");
        }

      }
    } catch (error) {
      toastCatchError(error)
    } finally {
      setLoading(false)
    }
  }
  const handleOnChange = e => {
    if (e.target.files) {
      setDocument(e.target.files[0]);
    }
  };


  return (
    <>
      <section className='LoginPage d-flex align-items-center'>
        <div className='custom-container'>
          <div className='row align-items-center justify-content-center GradientColor'>
            {/* Left Side Bar Sec */}
            <div className='col-md-6 nopadding PositionRelative'>
              <div className='LeftSideDiv'>
                <h2 className='h2-style'> Get <br /> Started <br /> Here! </h2>
                <p className='Credentinals'> Secure and Simplify your document management here... </p>
              </div>
              <div className='leftbg'>
                <img src={leftbg} alt="LeftBg" />
              </div>
            </div>
            {/* Left Side Bar Sec Ends */}
            {/* Right Side Bar Sec */}
            <div className='col-md-6 nopadding'>
              <div className='RightSideDiv'>
                <div className='row'>
                  <div className='col-lg-8 col-md-12 offset-lg-2 offset-md-0'>
                    <div className='App_Name'>
                      App Name
                    </div>
                    <div className='PageTitle mt-4'>
                      <h2 className='FormHeading'> KYC Verification </h2>
                      <p> Need to upload any one of the documents mentioned </p>
                    </div>
                    <form onSubmit={handleUploadKYC}>
                      <div className="row">
                        <div className="col-md-12 mt-4">
                          <label className='form-label'>Select documents*</label>
                          <select className="InputField" onChange={(e) => setDocumentType(e.target.value)}>
                            <option value=""> Select documents </option>
                            <option value="passport"> Passport </option>
                            <option value="government_document"> Government Documents </option>
                            <option value="driving_licence"> Driving Licence </option>
                          </select>

                          {error & !documentType ? (
                            <p className="text-danger">This field is required.</p>
                          ) : null}
                        </div>
                        <div className="col-md-12 mt-4">
                          <label className='form-label'>Upload*</label>
                          <label className='UploadDoc' for='UploadFile'>
                            <input type='file' id="UploadFile" accept='image/*,application/pdf' onChange={handleOnChange} />
                            {
                              document ?
                                <p> {document?.name.toUpperCase()} </p>
                                : <p> Upload Document </p>
                            }
                          </label>

                          {error && !document ? (
                            <p className="text-danger">File required.</p>
                          ) : null}
                        </div>
                        <div className='col-md-12 mt-4'>

                          <button type="submit" onClick={handleUploadKYC} disabled={loading ? true : false} className='ButtonStyle'>
                            {
                              loading ? "Loading ..." : "Upload"
                            }
                          </button>
                          <Link to="/dashboard" > <Badge bg="secondary"> Skip </Badge> </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Right Side Bar Sec Ends */}
          </div>
        </div>
      </section>
    </>
  )
}

export default KYCverification
