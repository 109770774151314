import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toastCatchError } from "../common/common";
import Logo from "../assets/images/Logo.png";
import { MdEdit, MdLogout, MdPassword } from "react-icons/md";

const Header = (prop) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoutFunction = () => {
    try {
      localStorage.clear();
      navigate("/login");
    } catch (error) {
      toastCatchError(error);
    }
  };

  const userInfo = JSON.parse(localStorage.getItem("authUser"));

  return (
    <>
      <section className="DashBoardPage">
        <div className="custom-container">
          <div className="row align-items-center">
            <div className="col-lg-10 col-md-10">
              <div className="DashTop-Bar">
                <ul className="d-flex align-items-center">
                  <li className="BackButton">
                    <Link to="/Dashboard">
                      <img src={Logo} alt="" width={50} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Dashboard"
                      className={
                        location.pathname === "/Dashboard" ? "active" : ""
                      }
                    >
                      {prop.DashBoard}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Buy"
                      className={location.pathname === "/Buy" ? "active" : ""}
                    >
                      {prop.Buy}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="MenuButton_Hover">
                <span className="MenuButton">...</span>
                <div className="EditProfile">
                  <ul>
                    <li>
                      <Link to="/edit-profile">
                        <MdEdit /> Edit Profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/change-password">
                        <MdPassword /> Change Password
                      </Link>
                    </li>
                    <li onClick={handleLogoutFunction}>
                      <a href="javascript:void(0);">
                        <MdLogout />
                        Logout{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;

Header.defaultProps = {
  DashBoard: "Dashboard",
  Buy: "Buy",
  ChangePwd: "Change Password",
  EditProfile: "Edit Profile",
  Logout: "Logout",
};
