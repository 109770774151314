//import logo from './logo.svg';
import "./App.css";
import "./assets/css/Custom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import Login from "./components/auth/Login";
// import Home from './components/Home';
import Signup from "./components/auth/Signup";
import PersonalDetail from "./components/PersonalDetail";
import KYCverification from "./components/KYCverification";
import Verify from "./components/auth/Verify";
import Dashboard from "./dashboard/Dashboard";
import Buy from "./dashboard/Buy";
import EditProfile from "./dashboard/EditProfile";
import * as React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from "./components/404/NotFound";
import { apiHeader } from "./services/axios";
import Loader from "./components/loader/loader";
import ChangePassword from "./components/auth/ChangePassword";

function AuthPages() {

  let userInfo = localStorage.getItem("authUser");
  const token = userInfo ? JSON.parse(userInfo).token : ""
  if (!token) {
    window.location.href = "/login"
  } else {
    return (
      <>
        <Outlet />
      </>
    );
  }
}

function AuthLayOut() {

  let userInfo = localStorage.getItem("authUser");
  const token = userInfo ? JSON.parse(userInfo).token : ""
  if (!token) {
    window.location.href = "/login"
  } else {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }
}

function App() {
  
  apiHeader()

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/loader" element={<Loader />} />

          <Route path="/" element={<AuthPages />}>
            <Route path="/personal-detail" element={<PersonalDetail />} />
            <Route path="/kyc-verification" element={<KYCverification />} />
          </Route>
          <Route path="/" element={<AuthLayOut />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/buy" element={<Buy />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/change-password" element={<ChangePassword />} />
          </Route>

          <Route path='/404' element={<NotFound />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
