// Mobile honeInput
import { Link, useLocation, useNavigate } from "react-router-dom";
import leftbg from "../../assets/images/leftbg.png";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { toastAlert, toastCatchError } from "../../common/common";
import { verifyOtp, verifyRegisterOTP } from "../../services/auth";
import { apiHeader } from "../../services/axios";
import Logo from "../../assets/images/Logo.png";


const Verify = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  
  const handleVerifyOTP = async (e) => {
    e.preventDefault()
    try {
      if (otp.length < 6) {

      } else {
        setLoading(true)

        console.log(state.from , "state.from")

        let result = 
          state.from == "signup" ? await verifyRegisterOTP({
            "device_token": "testing",
            "phone_number": state.phoneNumber,
            "otp": otp
          }) : await verifyOtp({
            "device_token": "testing",
            "phone_number": state.phoneNumber,
            "otp": otp
          })

         
        if (result.status == 200) {
          
          localStorage.setItem("authUser", JSON.stringify(result.data));
          await apiHeader()
          
          if (state.from == "signup") {
            navigate("/personal-detail")
          } else {
            navigate("/dashboard")
          }
        }
      }
      if (otp === "123456") {
      }
    } catch (error) {
      toastCatchError(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <section className="LoginPage d-flex align-items-center">
        <div className="custom-container">
          <div className="row align-items-center justify-content-center GradientColor">
            {/* Left Side Bar Sec */}
            <div className="col-md-6 nopadding PositionRelative">
              <div className="LeftSideDiv">
                <h2 className="h2-style">
                  Enter OTP
                  <br />
                  to verify
                </h2>
                <p className="Credentinals"> Verify your Mobile Number in order to sign up using an OTP received through SMS </p>
              </div>
              <div className="leftbg">
                <img src={leftbg} alt="LeftBg" />
              </div>
            </div>
            {/* Left Side Bar Sec Ends */}
            {/* Right Side Bar Sec */}
            <div className="col-md-6 nopadding">
              <div className="RightSideDiv">
                <div className="row">
                  <div className="col-lg-8 col-md-12 offset-lg-2 offset-md-0">
                    <div className="App_Name"><img src={Logo} alt="" width={50} />bitcoinify</div>
                    <div className="PageTitle mt-4">
                      <h2 className="FormHeading">OTP Verification</h2>
                    </div>
                    <form onSubmit={handleVerifyOTP}>
                      <div className="row">
                        <div className="col-md-12 mt-5 mb-5">
                          <div className="VerifyCode">
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              inputStyle="otp-input"
                              renderSeparator={<span className="px-2"> - </span>}
                              renderInput={(props) => <input
                                {...props} />}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <button type="submit" onClick={handleVerifyOTP} disabled={loading ? true : false} className='ButtonStyle'>
                            {
                              loading ? "Loading ..." : "Verify OTP"
                            }
                          </button>

                          {/* <div className="SignUphere">  Didn't recieve the OTP?{" "} <Link to="/login">Resend OTP</Link> </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Right Side Bar Sec Ends */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Verify;
