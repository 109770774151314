import React, { useEffect, useState } from "react";
import biticon from "../assets/images/b-icon.png";
import ticon from "../assets/images/t-icon.png";
import arrowicon from "../assets/images/arrow-icon.png";
import filtericon from "../assets/images/filtericon.png";
import invoiceicon from "../assets/images/invoice-icon.png";
import { DatePicker, Pagination } from "antd";
import { transactions } from "../services/auth";
import { toastCatchError } from "../common/common";

const Dashboard = () => {
  const [isLoading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [filterOption, setFilterOption] = useState(5); // Default to "All"
  const { RangePicker } = DatePicker;

  const arr = [
    {
      id: 1,
      IconImg: biticon,
      Transaction: "$325.10",
      TransactionID: "#12345678",
      Date: "Nov 21, 2024",
      Status: "Pending",
      Download: "Invoice",
    },
    {
      id: 2,
      IconImg: ticon,
      Transaction: "$6.0",
      TransactionID: "#12345678",
      Date: "Nov 21, 2024",
      Status: "Completed",
      Download: "Invoice",
    },
  ];

  const getTestTransactions = async () => {
    try {
      setLoading(true);
      const [startDate, endDate] = dateRange;
      const params = {
        page: currentPage,
        limit: pageSize,
        startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
        endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
        filter: filterOption,
      };

      let result = await transactions(params);
      if (result.status === 200) {
        let userInfo = localStorage.getItem("authUser");
        const { token } = JSON.parse(userInfo);
        localStorage.setItem("authUser", JSON.stringify({ ...result.data, token }));
        
        setTransaction(result.data.transactions);
        setTotal(result.data.total);
      }
    } catch (error) {
      toastCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTestTransactions();
  }, [currentPage, pageSize, dateRange, filterOption]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleFilterChange = (e) => {
    setFilterOption(parseInt(e.target.value, 10));
  };

  return (
    <>
      <section className="TableSection">
        <div className="custom-container">
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="Transaction">
                <h2>Transaction Details</h2>
              </div>
            </div>
            <div className="col-md-8">
              <div className="FilterDiv">
                <ul className="d-flex align-items-center justify-content-end">
                  <li>
                    <a href="#!">
                      <img src={filtericon} alt="filtericon" /> Filter By
                    </a>
                  </li>
                  <li>
                    <select onChange={handleFilterChange}>
                      <option value={1}>Last 7 Days</option>
                      <option value={2}>Last Month</option>
                      <option value={3}>Last 6 Months</option>
                      <option value={4}>Year</option>
                      <option value={5}>All</option>
                    </select>
                  </li>
                  <li>
                    <RangePicker onChange={(dates) => setDateRange(dates)} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="TableDiv">
                <table width="100%" cellSpacing={0} className="TableStyle">
                  <thead>
                    <tr>
                      <th>
                        Coin <img src={arrowicon} alt="arrowicon" />
                      </th>
                      <th>
                        Transaction <img src={arrowicon} alt="arrowicon" />
                      </th>
                      <th>
                        Transaction ID <img src={arrowicon} alt="arrowicon" />
                      </th>
                      <th>
                        Date <img src={arrowicon} alt="arrowicon" />
                      </th>
                      <th>
                        Status <img src={arrowicon} alt="arrowicon" />
                      </th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arr?.map((data) => (
                      <tr key={data.id}>
                        <td>
                          <img src={data.IconImg} alt="icon" />
                        </td>
                        <td className="Transactionprice">
                          {data.Transaction}{" "}
                          <span className="SubText">Bought BTC</span>
                        </td>
                        <td>{data.TransactionID}</td>
                        <td>{data.Date}</td>
                        <td className={data.Status === "Pending" ? "Pending" : "Completed"}>
                          <span>{data.Status}</span>
                        </td>
                        <td>
                          <div className="invoice">
                            {data.Download} <img src={invoiceicon} alt="icon" />
                          </div>
                        </td>
                      </tr>
                    ))}
                    {transaction?.map((val) => (
                      <tr key={val?._id}>
                        <td>
                          <img src={biticon} alt="icon" />
                        </td>
                        <td className="Transactionprice">
                          {val?.sourceCurrency} {val?.inAmount}
                          <span className="SubText">Bought BTC</span>
                        </td>
                        <td>{val?.onrampTransactionId}</td>
                        <td>{val?.statusDate}</td>
                        <td className={val?.status === "Pending" ? "Pending" : "Completed"}>
                          <span>{val?.status}</span>
                        </td>
                        <td>
                          <div className="invoice">
                            Invoice
                            <img src={invoiceicon} alt="icon" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="text-center mt-4">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={total}
                  onChange={handlePageChange}
                  showSizeChanger
                  onShowSizeChange={handlePageChange}
                />
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
