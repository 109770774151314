import axios from "axios"
import moment from "moment"


//apply base url for axios
const API_URL =
  window.location.origin.includes("dev") ||
  window.location.origin.includes("localhost")
    ? "https://dev-apis.bitcoinify.global/api"
    : "https://dev-apis.bitcoinify.global/api"


export const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "x-key": "",
  },
})


export const get = async (url, config = {}) => {
  try {
    const responce = await axiosApi.get(url, { ...config })
    return responce?.data
  } catch (error) {
    if (
      error?.response?.data.error === "Unauthenticated"
    ) {
      localStorage.removeItem("authUser")
      window.location.replace("/login")
    }
  }
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function postAsForm(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}
export async function putAsForm(url, data, config = {}) {
  return axiosApi.put(url, data, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, id, config = {}) {
  return await axiosApi
    .delete(
      url,
      {
        params: { id },
      },
      { ...config }
    )
    .then(response => response.data)
}

export const apiHeader = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  console.log(obj,"objobj")
  if (obj && obj.token) {
    axiosApi.defaults.headers.common["Authorization"] = obj.token
  } else {
    return {}
  }
}
