import React, { useEffect, useState } from "react";

// Mobile honeInput
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import leftbg from "../../assets/images/leftbg.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registerOTP } from "../../services/auth";
import { toastCatchError } from "../../common/common";

const Signup = () => {

  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneEmpty, setPhoneEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("first name is required"),
      lastName: Yup.string().required("last name is required"),
      email: Yup.string().email("plz provide valid email address.").required("email is required."),
    }),
    onSubmit: async (values) => {
      if (phoneNumber.length > 8) {
        setPhoneEmpty(false)
        registerSendOTP(values)

      } else {
        setPhoneEmpty(true)
      }
    },
  });

  const registerSendOTP = async (data) => {
    try {
      setLoading(true)
      
      let result = await registerOTP({
        "phone_number": phoneNumber,
        "last_name": data?.lastName,
        "first_name": data?.firstName,
        "email": data?.email
      })
      if (result.status == 200) {
        navigate("/verify", { state: { phoneNumber: phoneNumber, from: "signup" } });
      }
    } catch (error) {
      toastCatchError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <section className="LoginPage d-flex align-items-center">
        <div className="custom-container">
          <div className="row align-items-center justify-content-center GradientColor">
            {/* Left Side Bar Sec */}
            <div className="col-md-6 nopadding PositionRelative">
              <div className="LeftSideDiv">
                <h2 className="h2-style">
                  Get
                  <br /> Started
                  <br />  Here!
                </h2>
                <p className="Credentinals"> Secure and Simplify your document management here... </p>
              </div>
              <div className="leftbg">
                <img src={leftbg} alt="LeftBg" />
              </div>
            </div>
            {/* Left Side Bar Sec Ends */}
            {/* Right Side Bar Sec */}
            <div className="col-md-6 nopadding">
              <div className="RightSideDiv">
                <div className="row">
                  <div className="col-lg-8 col-md-12 offset-lg-2 offset-md-0">
                    <div className="App_Name">App Name</div>
                    <div className="PageTitle mt-4">
                      <h2 className="FormHeading">Sign Up</h2>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mt-4">
                          <label className="form-label">First Name*</label>
                          <input
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.firstName && formik.errors.firstName ? (
                            <p className="text-danger">{formik.errors.firstName}</p>
                          ) : null}

                        </div>
                        <div className="col-md-6 mt-4">
                          <label className="form-label">Last Name*</label>
                          <input
                            name="lastName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <p className="text-danger">{formik.errors.lastName}</p>
                          ) : null}
                        </div>

                        <div className="col-md-12 mt-4">
                          <label className="form-label">Email*</label>
                          <input
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            placeholder="Write here..."
                            className="InputField"
                          />


                          {formik.touched.email && formik.errors.email ? (
                            <p className="text-danger">{formik.errors.email}</p>
                          ) : null}

                        </div>
                        <div className="col-md-12 mt-4">
                          <label className="form-label"> Phone number* </label>
                          <PhoneInput
                            className="PhoneField"
                            defaultCountry="in"
                            placeholder="Write here..."
                            onChange={(phone) => setPhoneNumber(phone)}
                          />

                          {phoneEmpty ? (
                            <p className="text-danger">Phone number is required.</p>
                          ) : null}
                        </div>
                        <div className="col-md-12 mt-4">
                          <button type="submit" disabled={loading ? true : false} className='ButtonStyle'>
                            {
                              loading ? "Loading ..." : "Sign Up"
                            }
                          </button>
                          <div className="SignUphere">
                            Already have an account?{" "}
                            <Link to="/login">Login here</Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Right Side Bar Sec Ends */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
