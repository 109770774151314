// import React from 'react'
import React from "react";


const Buy = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  console.log("localStorage.getItem();", obj._id);
  return (
    <>
      <section>
        <div className="custom-container">
          <div className="row mt-5">
            <div>
              <div>
                <div className="row">
                  <iframe
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #58585f",
                      margin: "auto",
                      maxWidth: "420px",
                    }}
                    src={`https://buy.onramper.com/?apiKey=pk_prod_01HM6B5MM8MKP6NA324DB9MTY5&partnerContext=${obj._id}&mode=buy&defaultFiat=usd&defaultCrypto=btc`}
                    height="630px"
                    width="420px"
                    title="Onramper widget"
                    API_KEY="pk_prod_01HM6B5MM8MKP6NA324DB9MTY5"
                    allow="accelerometer; autoplay; camera; gyroscope; payment"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Buy;
// &onlyOnramps=gatefi,transfi
// &onlyOnramps=gatefi,transfi
// &defaultPaymentMethod=creditcard